import 'bootstrap/dist/css/bootstrap.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './App.css';
import Designer from "./component/Designer";

function App() {
  return (
    <div className="App">
      <Designer />
    </div>
  );
}

export default App;
