import React from "react";
import FontAwesome from "react-fontawesome";

function SidebarHeader(props) {
   let lang = props.lang;

    return <React.Fragment>
        <div className={'menu-header'}>
            <div className={'change-language'} onClick={() => {
                props.onShowLanguages();
            }}>
                <FontAwesome name={'chevron-left'} className={'mr-2'}/>
                {props.getTranslationPhrase('Change language',lang)}

            </div>
            <div className={'row no-gutters justify-content-between headers-block'}>
                <div className={'col-auto title'}>
                    <span>{props.getTranslationPhrase('Configurator',lang)} </span>
                </div>
                <div className={'col-auto brand'}><img src={require('./../assets/images/logo.png').default} /></div>
            </div>
        </div>
    </React.Fragment>
}
export default SidebarHeader;