import React,{Component} from 'react';
import FontAwesome from 'react-fontawesome';
import {Modal} from 'react-bootstrap';
import SidebarHeader from "./SidebarHeader";
import axios from "axios";
import { fabric } from "fabric/dist/fabric";
import Loader from "react-loader-spinner";

class Designer extends Component{

    state = {
        isEmailModal:false,
        isLanguageModal:false,
        isAlertModal:false,
        isAlertChangeLanguageModal:false,
        isLoader:true,
        isPDFModal:false,
        products:[

        ],
        model:[],
        menu:[],
        activeModel:{},
        activeProduct:{},
        activeDetails:[],
        activeDetail:{},
        activeLayer:{},
        type:'products',
        mainUrl:'https://adm.breclavconfigurator.com',
        imageData: "",
        renderData:[],
        sideDropdown: {
            value: 0,
        },
        notes:'',
        fileUrl:'',
        emailFormData:{
            from:'',
            to:'',
            subject:'',
            message:''
        },
        zoom:1,
        lang:'en',
        translations:{}

    }

    constructor(props) {
        super(props);

        this.canvasRef = React.createRef();


    }

    getTranslation = async () => {
        let response = await axios.get(`${this.state.mainUrl}/api/frontend-translation`);
        return response;
    }

    componentDidMount() {
        axios.get(`${this.state.mainUrl}/api/knot-groups`).then(response => {
            this.setState({
                products:[...response.data],
                isLoader:false
            }, () => {
                this.getTranslation().then(response => {
                    this.setState({
                        translations:{...response.data}
                    })
                })
                this.setHeightSidebar()
                window.addEventListener("resize", () => {
                    this.setHeightSidebar()
                });
            })
        });
        if(!!window.localStorage.getItem('lang')){
            this.setState({
                lang:window.localStorage.getItem('lang')
            })
        } else {
            window.localStorage.setItem('lang','en')
        }

        window.history.pushState(null, null, window.location.href);
        window.history.back();
        window.onpopstate = (e) => {
            window.history.forward();
        }


    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setHeightSidebar()
       // window.onpopstate = () => {}
    }

    onHideLanguages = () => {
        this.setState({
            isLanguageModal:false
        })
    }

    onShowLanguages = () => {
        this.setState({
            isLanguageModal:true
        })
    }

    initDesignerMenu = async () => {
        let response = await axios.get(
            this.state.mainUrl + `/api/menu/${this.state.activeProduct.id}`,
            {
                params: {
                    ...this.state.model
                }
            }
        );

        return response;
    };

    zoomImage = () => {

    }

    setHeightSidebar = () => {

        if(window.innerWidth > 992){
            let height = document.getElementById('canvas-block-model').offsetHeight;
            let sidebarTopHeight = document.getElementById('sidebar-top').offsetHeight;
            document.getElementById('sidebar-content').style.height = `${height - sidebarTopHeight}px` ;
            document.getElementById('sidebar-content').style.overflowY = 'scroll';
            document.querySelector('.sidebar-menu').removeAttribute('style')
        }
        else{
            document.querySelector('.sidebar-menu').style.maxHeight = `100vh`;
            document.querySelector('.sidebar-menu').style.overflowY = `scroll`;
            document.getElementById('sidebar-content').removeAttribute('style')
        }

    }

    initKnotType = (product) => {
        this.setState({
            isLoader:true
        },() => {
            axios.get(this.state.mainUrl + `/api/knotType/${product.id}`).then(response => {
                this.setState({
                    model:{
                        ...response.data
                    },
                    activeProduct:{
                        ...product
                    },
                    type:'groups',
                    activeModel:{
                        ...response.data
                    }
                },() => {
                    this.getModelRenderData().then((dataRender) => {
                        // let objectlist = [];
                        this.setState(
                            {
                                renderData: [...dataRender]
                            },
                            () => {
                                this.initDesignerMenu().then(response => {
                                    this.setState({
                                        activeDetails:[...response.data[0].layers_groups],
                                        isLoader:false
                                    })
                                });
                                this.renderCanvas();

                            }
                        );
                    });


                })

            });
        })

    }

    downloadPDF = async () => {

         let image = await axios.post(`${this.state.mainUrl}/api/save-image`,{
                imageData:this.state.imageData
            });
         let pdf = await axios.post(`${this.state.mainUrl}/api/pdf-test`,{

                        renderData: btoa(encodeURIComponent (JSON.stringify( this.state.renderData))) ,
                        imageData:image.data,
                        activeProduct:this.state.activeProduct,
                        notes:this.state.notes,
                        lang:this.state.lang

                });
         return pdf;




    }

    // initDesignerMenu = async () => {
    //
    //     let response = await axios.get(
    //         this.state.mainUrl + `/api/menu/${this.state.activeProduct.id}`,
    //         {
    //             params: {
    //                 ...this.state.model
    //             }
    //         }
    //     );
    //
    //     return response;
    // };
    renderCanvas = () => {
        let koefSize = 1;
        let widthCanvas = 1500 * koefSize;
        let heightCanvas = 1500 * koefSize;


        let canvas = new fabric.Canvas("guitar", {
            width: widthCanvas,
            height: heightCanvas,
            selection: false
        });

        //canvas.clearAll();

        let layers = [...this.state.renderData];

        let siteUrl = this.state.mainUrl;
        let imageLoaded = 0;
        let limit = this.state.renderData.length;
        let imageData = [];
        let order = true;
        if (
            this.state.sideDropdown.value === 2 ||
            this.state.sideDropdown.value === 3
        ) {
            order = false;
        }

        let buildImage = (i, layer) => {
            var wordo = siteUrl + layer.img;
            let z_index = layer.z_index;
            let color = layer.color;
            let wordo2 = layer.img_mask;
            let color2 = layer.color2;

            fabric.Image.fromURL(
                wordo,
                (mask) => {
                    mask.scaleToHeight(heightCanvas);
                    mask.scaleToWidth(widthCanvas);
                    switch (layer.colorType) {
                        case 1:
                            var filter = new fabric.Image.filters.BlendColor({
                                color: color,
                                mode: "tint",
                                alpha: 1
                            });
                            mask.filters.push(filter);
                            mask.applyFilters();
                            imageData.push({ z_index: z_index, img: mask });

                            if (limit === ++imageLoaded) {
                                setGroup();
                            }
                            break;
                        case 2:
                            color = siteUrl + "/storage/" + color;

                            fabric.Image.fromURL(
                                color,
                                (pattern) => {
                                    var maskCanvas = new fabric.StaticCanvas("additional", {
                                        width: widthCanvas,
                                        height: heightCanvas,
                                        lockMovement: true
                                    });
                                    maskCanvas.add(mask);

                                    maskCanvas.add(pattern);
                                    pattern.globalCompositeOperation = "source-in";
                                    maskCanvas.renderAll();

                                    let resultImg = maskCanvas.toDataURL("png");

                                    fabric.Image.fromURL(
                                        resultImg,
                                        (resultImageObject) => {
                                            imageData.push({
                                                z_index: z_index,
                                                img: resultImageObject
                                            });

                                            if (limit === ++imageLoaded) {
                                                setGroup();
                                            }
                                        },
                                        {
                                            crossOrigin: "Anonymous"
                                        }
                                    );
                                },
                                {
                                    crossOrigin: "Anonymous"
                                }
                            );

                            break;
                        case "":
                            imageData.push({ z_index: z_index, img: mask });

                            if (limit === ++imageLoaded) {
                                setGroup();
                            }
                            break;

                        case 3:
                            var filter = new fabric.Image.filters.BlendColor({
                                color: color,
                                mode: "tint",
                                alpha: 1
                            });
                            mask.filters.push(filter);
                            mask.applyFilters();

                            if (color2 !== "" && wordo2 !== "/storage/") {
                                fabric.Image.fromURL(
                                    siteUrl + wordo2,
                                    (color_mask) => {
                                        var filter2 = new fabric.Image.filters.BlendColor({
                                            color: color2,
                                            mode: "tint",
                                            alpha: 1
                                        });
                                        color_mask.filters.push(filter2);
                                        color_mask.applyFilters();
                                        var maskCanvas = new fabric.StaticCanvas("additional", {
                                            width: widthCanvas,
                                            height: heightCanvas,
                                            lockMovement: true
                                        });
                                        maskCanvas.add(mask);

                                        maskCanvas.add(color_mask);
                                        // color_mask.globalCompositeOperation = "source-in";
                                        maskCanvas.renderAll();

                                        let resultImg = maskCanvas.toDataURL("png");
                                        fabric.Image.fromURL(resultImg, (resultImgObj) => {
                                            imageData.push({ z_index: z_index, img: resultImgObj });

                                            if (limit === ++imageLoaded) {
                                                setGroup();
                                            }
                                        });
                                    },
                                    {
                                        crossOrigin: "Anonymous"
                                    }
                                );
                            } else {
                                imageData.push({ z_index: z_index, img: mask });

                                if (limit === ++imageLoaded) {
                                    setGroup();
                                }
                            }

                            break;
                        case 4:
                            var filter = new fabric.Image.filters.BlendColor({
                                color: color,
                                mode: "tint",
                                alpha: 1
                            });
                            mask.filters.push(filter);
                            mask.applyFilters();

                            if (color2 !== "" && wordo2 !== "/storage/") {
                                fabric.Image.fromURL(
                                    siteUrl + wordo2,
                                    (color_mask) => {
                                        fabric.Image.fromURL(
                                            siteUrl + "/storage/" + color2,
                                            (pattern) => {
                                                var maskCanvas = new fabric.StaticCanvas("additional", {
                                                    width: widthCanvas,
                                                    height: heightCanvas,
                                                    lockMovement: true
                                                });
                                                maskCanvas.add(color_mask);

                                                maskCanvas.add(pattern);
                                                pattern.globalCompositeOperation = "source-in";
                                                maskCanvas.renderAll();

                                                let resultImg = maskCanvas.toDataURL("png");
                                                fabric.Image.fromURL(resultImg, (color_mask_obj) => {
                                                    var maskCanvas2 = new fabric.StaticCanvas(
                                                        "additiona2",
                                                        {
                                                            width: widthCanvas,
                                                            height: heightCanvas,
                                                            lockMovement: true
                                                        }
                                                    );
                                                    maskCanvas2.add(mask);

                                                    maskCanvas2.add(color_mask_obj);
                                                    // pattern.globalCompositeOperation = "source-in";
                                                    maskCanvas2.renderAll();

                                                    let resultImg2 = maskCanvas2.toDataURL("png");

                                                    fabric.Image.fromURL(resultImg2, (imgObj) => {
                                                        imageData.push({ z_index: z_index, img: imgObj });

                                                        if (limit === ++imageLoaded) {
                                                            setGroup();
                                                        }
                                                    });
                                                });
                                            },
                                            {
                                                crossOrigin: "Anonymous"
                                            }
                                        );
                                    },
                                    {
                                        crossOrigin: "Anonymous"
                                    }
                                );
                            } else {
                                imageData.push({ z_index: z_index, img: mask });

                                if (limit === ++imageLoaded) {
                                    setGroup();
                                }
                            }

                            break;
                        case 5:
                            fabric.Image.fromURL(
                                siteUrl + "/storage/" + color,
                                (pattern1) => {
                                    var maskCanvas = new fabric.StaticCanvas("additional", {
                                        width: widthCanvas,
                                        height: heightCanvas,
                                        lockMovement: true
                                    });
                                    maskCanvas.add(mask);

                                    maskCanvas.add(pattern1);
                                    pattern1.globalCompositeOperation = "source-in";
                                    maskCanvas.renderAll();

                                    let resultImg1 = maskCanvas.toDataURL("png");
                                    fabric.Image.fromURL(
                                        resultImg1,
                                        (img_result_1) => {
                                            if (color2 !== "" && wordo2 !== "/storage/") {
                                                fabric.Image.fromURL(
                                                    siteUrl + wordo2,
                                                    (color_mask_2) => {

                                                        var filter = new fabric.Image.filters.BlendColor({
                                                            color: color2,
                                                            mode: "tint",
                                                            alpha: 1
                                                        });
                                                        color_mask_2.filters.push(filter);
                                                        color_mask_2.applyFilters();
                                                        var maskCanvas1 = new fabric.StaticCanvas(
                                                            "additiona2",
                                                            {
                                                                width: widthCanvas,
                                                                height: heightCanvas,
                                                                lockMovement: true
                                                            }
                                                        );
                                                        maskCanvas1.add(img_result_1);

                                                        maskCanvas1.add(color_mask_2);

                                                        maskCanvas1.renderAll();

                                                        let resultImg2 = maskCanvas1.toDataURL("png");

                                                        fabric.Image.fromURL(resultImg2, (img_result_2) => {
                                                            imageData.push({
                                                                z_index: z_index,
                                                                img: img_result_2
                                                            });

                                                            if (limit === ++imageLoaded) {
                                                                setGroup();
                                                            }
                                                        });
                                                    },
                                                    {
                                                        crossOrigin: "Anonymous"
                                                    }
                                                );
                                            } else {
                                                imageData.push({ z_index: z_index, img: img_result_1 });

                                                if (limit === ++imageLoaded) {
                                                    setGroup();
                                                }
                                            }
                                        },
                                        {
                                            crossOrigin: "Anonymous"
                                        }
                                    );
                                },
                                {
                                    crossOrigin: "Anonymous"
                                }
                            );

                            break;
                        case 6:
                            fabric.Image.fromURL(
                                siteUrl + "/storage/" + color,
                                (pattern1) => {
                                    var maskCanvas = new fabric.StaticCanvas("additional", {
                                        width: widthCanvas,
                                        height: heightCanvas,
                                        lockMovement: true
                                    });
                                    maskCanvas.add(mask);

                                    maskCanvas.add(pattern1);
                                    pattern1.globalCompositeOperation = "source-in";
                                    maskCanvas.renderAll();

                                    let resultImg1 = maskCanvas.toDataURL("png");
                                    fabric.Image.fromURL(
                                        resultImg1,
                                        (result_img1) => {
                                            if (color2 !== "" && wordo2 !== "/storage/") {
                                                fabric.Image.fromURL(
                                                    siteUrl + wordo2,
                                                    (mask_color) => {
                                                        fabric.Image.fromURL(
                                                            siteUrl + "/storage/" + color2,
                                                            (pattern2) => {
                                                                var maskCanvas1 = new fabric.StaticCanvas(
                                                                    "additiona2",
                                                                    {
                                                                        width: widthCanvas,
                                                                        height: heightCanvas,
                                                                        lockMovement: true
                                                                    }
                                                                );
                                                                maskCanvas1.add(mask_color);

                                                                maskCanvas1.add(pattern2);
                                                                pattern2.globalCompositeOperation = "source-in";
                                                                maskCanvas1.renderAll();

                                                                let resultImg2 = maskCanvas1.toDataURL("png");
                                                                fabric.Image.fromURL(
                                                                    resultImg2,
                                                                    (result_img2) => {
                                                                        var maskCanvas2 = new fabric.StaticCanvas(
                                                                            "additiona3",
                                                                            {
                                                                                width: widthCanvas,
                                                                                height: heightCanvas,
                                                                                lockMovement: true
                                                                            }
                                                                        );
                                                                        maskCanvas2.add(result_img1);

                                                                        maskCanvas2.add(result_img2);
                                                                        //  pattern2.globalCompositeOperation = "source-in";
                                                                        maskCanvas2.renderAll();

                                                                        let resultImg3 = maskCanvas2.toDataURL(
                                                                            "png"
                                                                        );

                                                                        fabric.Image.fromURL(
                                                                            resultImg3,
                                                                            (result_img3) => {
                                                                                imageData.push({
                                                                                    z_index: z_index,
                                                                                    img: result_img3
                                                                                });

                                                                                if (limit === ++imageLoaded) {
                                                                                    setGroup();
                                                                                }
                                                                            }
                                                                        );
                                                                    }
                                                                );
                                                            },
                                                            {
                                                                crossOrigin: "Anonymous"
                                                            }
                                                        );
                                                    },
                                                    {
                                                        crossOrigin: "Anonymous"
                                                    }
                                                );
                                            } else {
                                                imageData.push({ z_index: z_index, img: result_img1 });

                                                if (limit === ++imageLoaded) {
                                                    setGroup();
                                                }
                                            }
                                        },
                                        {
                                            crossOrigin: "Anonymous"
                                        }
                                    );
                                    //document.write(`<img src="${resultImg1}" >`);
                                },
                                {
                                    crossOrigin: "Anonymous"
                                }
                            );

                            break;
                    }
                },
                {
                    crossOrigin: "Anonymous"
                }
            );
        };

        let setGroup = () => {
            if (order) {
                imageData.sort((a, b) => {
                    return a.z_index > b.z_index ? 1 : -1;
                });
            } else {
                imageData.sort((a, b) => {
                    return a.z_index < b.z_index ? 1 : -1;
                });
            }

            let result = [];
            imageData.forEach((itemData) => {
                result.push(itemData.img);
            });

            let group = new fabric.Group(result, {
                top: 0,
                left: 0,
                selection: false,
                selectable: false,
                lockMovementX: true,
                lockMovementY: true,
                lockRotation: true,
                lockScalingX: true,
                lockScalingY: true,
                lockUniScaling: true,
                hoverCursor: "auto",
                evented: false
            });

            canvas.add(group);
            canvas.renderAll();
            let canvasImage = canvas.toDataURL('image/jpeg', 1.0);

            this.setState({
                imageData: canvasImage
            });
            imageData = [];
        };

        layers.forEach((layer, index) => {
            if (layer.img !== "/storage/") {
                buildImage(index, layer);
            }
        });
    };

    onUpdateLayerHandler = (id_layer, id_color, type) => {
        this.setState({
            isLoader:true
        },
        () => {
            axios
                .post(
                    this.state.mainUrl +
                    `/api/updateLayerModel/${id_layer}/color/${id_color}/knotGroup/${this.state.activeProduct.id}`,
                    {
                        params: {
                            ...this.state.model
                        }
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        this.setState(
                            {
                                model: [...response.data]
                            },
                            () => {


                                this.getModelRenderData().then((dataRender) => {
                                    // let objectlist = [];
                                    this.setState(
                                        {
                                            renderData: [...dataRender]
                                        },
                                        () => {
                                            this.initDesignerMenu().then(response => {
                                                this.setState({
                                                    activeDetails:[...response.data[0].layers_groups],
                                                    isLoader:false,
                                                    type:'groups'
                                                })
                                            });
                                            this.renderCanvas();

                                        }
                                    );
                                });
                            }
                        );
                    }
                });
        })

    };

    filterRenderByGroup = (id_group) =>  {
        let model = [...this.state.renderData];

        let item = (model.filter(layer => layer.id_group === id_group ));

        return item;
    }

    getModelRenderData = async () => {
        let response = await axios.get(
            this.state.mainUrl +
            `/api/modelRenderData/0`,
            {
                params: {
                    ...this.state.model
                }
            }
        );

        return response.data;
    };


    sendEmail = () => {
        axios.post(
            this.state.mainUrl +
            '/api/send-email-product',
            {
                formData:this.state.emailFormData,
                fileUrl:this.state.fileUrl
            }
        ).then(response => {
            this.setState({
                isEmailModal:false,
                emailFormData:{
                    from:'',
                    to:'',
                    subject:'',
                    message:''
                },
            })
        });
    }

    zoomPlus = () =>{
        if(this.state.zoom < 1.4){
            this.setState({
                zoom:this.state.zoom + 0.2
            })
        }

    }

    zoomMinus = () => {
        if(this.state.zoom > 0.7){
            this.setState({
                zoom:this.state.zoom - 0.2
            })
        }

    }

    zoomDefault = () => {

    }

    getTranslationPhrase = (key, lang) => {
        let phrase = '';
        if(Object.keys(this.state.translations).length > 0){
            phrase = this.state.translations[key][lang];
           // console.log(this.state.translations[key], key, this.state.translations)
        }

        return phrase;
    }

    changeLanguage = lang => {
       // if(window.confirm('When you change language for configuration, you lost configuration of actual product.')){
            this.setState({
                lang:lang,
                isLanguageModal:false,
            }, () => {
                window.localStorage.setItem('lang', lang )
            })
     //   }

    }


    render(){


        let lang = this.state.lang;

        return <React.Fragment>
            <div className={'container-fluid position-relative designer-container px-0'}>
                <div className={'row no-gutters'}>
                    <div className={'col-lg-8 canvas-container-block'}>
                        {
                            this.state.type === 'products'
                                ? <img id={"canvas-block-model"} className={'image-home-bg'} src={require('./../assets/images/intro-picture_2000x2000px.jpg').default} />
                                : <div id={"canvas-block-model"} >
                                    <canvas
                                        style={{ display: "none" }}
                                        id={"guitar"}
                                        ref={this.canvasRef}
                                    />
                                    <div className={'canvas-img-container text-center'}>
                                        {this.state.imageData !== "" ? (

                                            <img

                                                src={this.state.imageData}
                                                style={{ width: "100%", transform:`scale(${this.state.zoom})` }}
                                            />
                                        ) : null}


                                    </div>

                                </div>
                        }


                    </div>
                    { this.state.type === 'products'
                        ? <div className={'col-lg-4 sidebar-menu'}>
                            <div id={'sidebar-top'}>
                                <SidebarHeader onShowLanguages={this.onShowLanguages} translations={this.state.translations} getTranslationPhrase={this.getTranslationPhrase} lang={this.state.lang}/>
                                <div className={'menu-nav row no-gutters'}>

                                    <div className={'title col-7'}><span>{this.getTranslationPhrase('Select product',lang)} </span></div>
                                    <div className={'button-nav col-5'}><span><FontAwesome name={'chevron-left'} className={'mr-2'}/> {this.getTranslationPhrase('Back',lang)} </span> </div>
                                </div>
                            </div>

                            <div className={'list-menu-nav'} id={'sidebar-content'}>
                                {
                                    this.state.products.map((product, index) =>  {{

                                        return <div className={'item-menu-nav'} key={`product_${index}`} >
                                            <div className={'left-block'}>
                                                <img
                                                    src={this.state.mainUrl + '/storage' + product.image}
                                                    onClick={() => {
                                                        this.initKnotType(product);
                                                    }}
                                                    className={'cursor-pointer'}

                                                />
                                                <span
                                                    className={'title cursor-pointer'}
                                                    onClick={() => {
                                                        this.initKnotType(product);
                                                    }}
                                                > {product[`name_${lang}`]}</span>

                                            </div>
                                            <div className={'right-block'}>
                                                <FontAwesome name={'check'} className={Object.keys(this.state.activeProduct).length > 0 && this.state.activeProduct.id === product.id ? 'active' :''} />
                                            </div>
                                        </div>
                                    }})
                                }


                            </div>
                        </div>
                        : null
                    }
                    {
                        this.state.type === 'groups'
                            ? <div className={'col-lg-4 sidebar-menu'}>
                                 <div  id={'sidebar-top'}>
                                     <SidebarHeader onShowLanguages={this.onShowLanguages} translations={this.state.translations} getTranslationPhrase={this.getTranslationPhrase} lang={this.state.lang} />
                                     <div className={'menu-nav row no-gutters'} style={{
                                         marginBottom:2
                                     }}>

                                         <div className={'title col-7'}><span>{this.state.activeProduct[`name_${lang}`]}</span></div>
                                         <div className={'button-nav col-5 justify-content-between'} ><span onClick={() => {
                                             this.setState({
                                                 isAlertModal:true
                                             })

                                         }}> {this.getTranslationPhrase('Select product',lang)}</span> <FontAwesome name={'chevron-right'} /></div>
                                     </div>


                                     <div className={'menu-nav row no-gutters'}  >

                                        <div className={'configuration col-7'}>
                                            {JSON.stringify(this.state.activeModel) !== JSON.stringify(this.state.model)
                                              ?  <span>{this.getTranslationPhrase('Custom Configuration',lang)} | <a href={''} onClick={event => {
                                                event.preventDefault();
                                                this.initKnotType(this.state.activeProduct);
                                            }}>{this.getTranslationPhrase('Reset',lang)}</a>
                                              </span>
                                            : <span>
                                                    {this.getTranslationPhrase('Standard Configuration',lang)}

                                                </span>}


                                        </div>
                                        <div
                                            className={'button-nav col-5 justify-content-between'}

                                        ><span
                                            onClick={() => {
                                                this.setState({
                                                    type:'summary',
                                                    isAlertModal:false
                                                })
                                            }}>{this.getTranslationPhrase('Summary & PDF',lang)} </span> <FontAwesome name={'chevron-right'} /></div>
                                    </div>
                                </div>
                                <div className={'list-menu-nav'}  id={'sidebar-content'}>
                                    {this.state.activeDetails.map((group,index) => {
                                       let modelItem = this.filterRenderByGroup(group.id);
                                        if(modelItem.length > 0){
                                            return <div className={'item-menu-nav'} key={`group_${index}`} >
                                                <div className={'left-block'}>
                                                    <img
                                                        src={this.state.mainUrl + '/storage/' + modelItem[0].layerPreview}
                                                        onClick={() => {
                                                            this.setState({
                                                                activeDetail:{...group},
                                                                type:'layers'
                                                            })
                                                        }}
                                                        className={'cursor-pointer'}
                                                        style={{
                                                            width:100
                                                        }}
                                                    />
                                                    <span
                                                        className={'d-inline-block cursor-pointer'}
                                                        onClick={() => {
                                                            this.setState({
                                                                activeDetail:{...group},
                                                                type:'layers'
                                                            })
                                                        }}
                                                    >
                                                        <div className={'title'}>{group[`name_${lang}`]}</div>
                                                        <div className={'category'}>{modelItem.length > 0 ? modelItem[0][`name_${lang}`] : null}</div>
                                                    </span>

                                                </div>
                                                <div className={'right-block'}>
                                                    <FontAwesome name={'chevron-right'} className={'active'} />
                                                </div>
                                            </div>
                                        }

                                    })}
                                </div>

                            </div>
                            : null
                    }

                    {
                        this.state.type === 'layers'
                            ? <div className={'col-lg-4 sidebar-menu'}>
                                <div  id={'sidebar-top'}>
                                    <SidebarHeader onShowLanguages={this.onShowLanguages}  translations={this.state.translations} getTranslationPhrase={this.getTranslationPhrase}  lang={this.state.lang}/>
                                    <div className={'menu-nav row no-gutters'} style={{
                                        marginBottom:2
                                    }}>

                                        <div className={'title col-7'}><span>{this.state.activeDetail[`name_${lang}`]}</span></div>
                                        <div className={'button-nav col-5 justify-content-between'}  onClick={() => {
                                            this.setState({
                                                type:'groups'
                                            })
                                        }}>
                                            <span><FontAwesome name={'chevron-left'} className={'mr-2'}/> {this.getTranslationPhrase('Back',lang)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={'list-menu-nav'} id={'sidebar-content'}>
                                    {this.state.activeDetail.layers.map((layer,index) => {
                                        let modelItem = this.filterRenderByGroup(layer.id_group);
                                        return <div className={'item-menu-nav'} key={`layer_${index}`} >
                                            <div className={'left-block'}>
                                                <img
                                                    src={this.state.mainUrl + '/storage/' + layer.preview}
                                                    onClick={() => {
                                                      this.onUpdateLayerHandler(layer.id, 0, "layer");
                                                    }}
                                                    className={'cursor-pointer'}
                                                     style={{
                                                         width:100
                                                     }}
                                                />
                                                <span
                                                    className={'d-inline-block cursor-pointer'}
                                                    onClick={() => {
                                                        this.onUpdateLayerHandler(layer.id, 0, "layer");
                                                    }}
                                                >
                                                    <div className={'category'}>{layer[`name_${lang}`]}</div>

                                                </span>

                                            </div>
                                            <div className={'right-block'}>
                                                <FontAwesome name={'check'}  className={modelItem.length > 0 && modelItem[0].id_layer === layer.id ? 'active' : ''} />
                                            </div>
                                        </div>
                                    })}
                                </div>

                            </div>
                            : null
                    }

                    {
                        this.state.type === 'summary'
                            ? <div className={'col-lg-4 sidebar-menu'}>
                                <div id={'sidebar-top'}>
                                    <SidebarHeader onShowLanguages={this.onShowLanguages}  translations={this.state.translations} getTranslationPhrase={this.getTranslationPhrase}  lang={this.state.lang}/>
                                    <div className={'menu-nav row no-gutters'} style={{
                                        marginBottom:2
                                    }}>

                                        <div className={'title col-7'}><span>Summary</span></div>
                                        <div className={'button-nav col-5 justify-content-between'}  onClick={() => {
                                            this.setState({
                                                type:'groups'
                                            })
                                        }}>
                                            <span><FontAwesome name={'chevron-left'} className={'mr-2'}/> {this.getTranslationPhrase('Back',lang)}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className={'result-configuration'}  id={'sidebar-content'}>
                                    <h2>{this.state.activeProduct[`name_${lang}`]}</h2>
                                    <div className={'model-list'}>
                                        {this.state.renderData.map((layer, index) => {
                                            return <React.Fragment key={`summary_${index}`}>
                                                {!layer.empty ? <div className={'model-item'}>
                                                    <div className={'title'}>
                                                        {layer[`groupName_${lang}`]}
                                                    </div>
                                                    <div className={'category'}>
                                                        {layer[`name_${lang}`]}
                                                    </div>
                                                </div> : null}

                                            </React.Fragment>
                                        })}
                                    </div>
                                    <div className={'notes'}>
                                        <div className={'title'}>{this.getTranslationPhrase('Notes',lang)}</div>
                                        <div className={'description'}>
                                            <textarea rows={3} value={this.state.notes} onChange={e => this.setState({notes:e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className={'buttons-block row no-gutters'}>
                                        <div className={'col-6'}>
                                            <button type={'button'} className={'btn btn-primary'} onClick={() => {
                                                this.setState({
                                                        isLoader:true
                                                    },() => {})
                                                this.downloadPDF().then(response => {
                                                    let href = `${this.state.mainUrl}/storage/${response.data}`;

                                                       // window.open(href, '_blank');
                                                        this.setState({
                                                            isLoader:false,
                                                            isPDFModal:true,
                                                            fileUrl:href
                                                        })


                                                });
                                            }}>
                                                <span>{this.getTranslationPhrase('Generate & Download PDF',lang)}</span>
                                                <FontAwesome name={'chevron-right'} />
                                            </button>
                                        </div>
                                        <div className={'col-6'}>
                                            <button type={'button'}  className={'btn btn-primary'}
                                                    onClick={event => {
                                                        event.preventDefault();
                                                        this.setState({
                                                            isLoader:true
                                                        },() => {
                                                            this.downloadPDF().then(response => {
                                                                let href = `${this.state.mainUrl}/storage/${response.data}`;


                                                                this.setState({
                                                                    isLoader:false,
                                                                    isEmailModal:true,
                                                                    fileUrl:href
                                                                })
                                                            });
                                                        })
                                                    }}
                                            >
                                            <span>
                                                {this.getTranslationPhrase('Generate & Share PDF',lang)}

                                            </span>
                                                <FontAwesome name={'chevron-right'} />
                                            </button>
                                        </div>

                                    </div>
                                    <div className={'footer-description'}>
                                        {this.getTranslationPhrase('Configurator is for illustrative purposes. Colors and finishes may vary from the digital images. For maximum color fidelity and depiction, consult with your Otis representative.',lang)}

                                    </div>
                                </div>


                            </div>
                            : null
                    }

                </div>
                {
                    this.state.type !== 'products'
                        ?<div className={'canvas-nav'}>

                            <a href={'#'} className={'nav-item'} onClick={event => {
                                event.preventDefault();
                                this.zoomPlus();
                            }}>
                                <FontAwesome name={'search-plus'} />
                            </a>
                            <a href={'#'} className={'nav-item'} onClick={event => {
                                event.preventDefault();
                                this.zoomMinus();
                            }}>
                                <FontAwesome name={'search-minus'} />
                            </a>

                        </div>
                        : null
                }

            </div>

            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                show={this.state.isEmailModal}
                onHide={() => this.setState({
                    isEmailModal:false
                    })
                }
                size={'lg'}
            >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <h2>{this.getTranslationPhrase('Send email',lang)}</h2>
                    <p>{this.getTranslationPhrase('Send configurated product by email. You may send this summary to multiple recipient by placing  commas between the emails.',lang)} </p>
                    <form onSubmit={event => {
                        event.preventDefault();
                        this.sendEmail();
                    }}>
                        <div className={'form-row'}>
                            <label  className={'col-3'}>{this.getTranslationPhrase('From',lang)}</label>
                            <div className={'col-7'}>
                                <input
                                    className={'form-control'}
                                    required={true}
                                    value={this.state.emailFormData.from}
                                    onChange={event => this.setState({
                                        emailFormData:{
                                            ...this.state.emailFormData,
                                            from:event.target.value
                                        }
                                    })}

                                />
                            </div>
                        </div>
                        <div className={'form-row'}>
                            <label  className={'col-3'}>{this.getTranslationPhrase('To',lang)}</label>
                            <div className={'col-7'}>
                                <input
                                    className={'form-control'}
                                    required={true}
                                    value={this.state.emailFormData.to}
                                    onChange={event => this.setState({
                                        emailFormData:{
                                            ...this.state.emailFormData,
                                            to:event.target.value
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className={'form-row'}>
                            <label  className={'col-3'}>{this.getTranslationPhrase('Subject',lang)}</label>
                            <div className={'col-7'}>
                                <input
                                    className={'form-control'}
                                    required={true}
                                    value={this.state.emailFormData.subject}
                                    onChange={event => this.setState({
                                        emailFormData:{
                                            ...this.state.emailFormData,
                                            subject:event.target.value
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className={'form-row'}>
                            <label  className={'col-3'}>{this.getTranslationPhrase('Attachment',lang)}</label>
                            <div className={'col-7'}>
                                <span style={{
                                    fontWeight:900
                                }}>
                                    {this.state.fileUrl}
                                </span>
                            </div>
                        </div>
                        <div className={'form-row'}>
                            <label  className={'col-3'}>{this.getTranslationPhrase('Message',lang)}</label>
                            <div className={'col-7'}>
                                <textarea
                                    className={'form-control'}
                                    required={true}
                                    value={this.state.emailFormData.message}
                                    rows={3}
                                    onChange={event => this.setState({
                                        emailFormData:{
                                            ...this.state.emailFormData,
                                            message:event.target.value
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className={'form-row'}>
                            <div className={'col-12 text-right row-buttons'}>
                                <button
                                    type={'submit'}
                                    className={'btn btn-primary'}

                                >{this.getTranslationPhrase('Send',lang)} <FontAwesome name={'chevron-right'}/></button>
                                <button
                                    type={'button'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            isEmailModal:false
                                        })
                                    }}
                                    className={'btn btn-danger'}>{this.getTranslationPhrase('Cancel',lang)} <FontAwesome name={'times'}/></button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                size={'lg'}
                aria-labelledby="contained-modal-title-vcenter"
                show={this.state.isLanguageModal}
                onHide={() => {
                    this.onHideLanguages()
                }}
            >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <h2>{this.getTranslationPhrase('Select language',lang)}</h2>
                    <p>{this.getTranslationPhrase('Changing language will reset actual configuration',lang)}</p>
                    <div className={'row list-languages text-center'}>
                        <div className={'col-auto item-language'} onClick={() => {
                            this.changeLanguage('en')
                        }}>
                            <img src={require('../assets/images/english.png').default}/>
                            <div className={'title'}>{this.getTranslationPhrase('English',lang)}</div>
                        </div>
                        <div className={'col-auto item-language'}onClick={() => {
                            this.changeLanguage('de')
                        }}>
                            <img src={require('../assets/images/german.png').default}/>
                            <div className={'title'}>{this.getTranslationPhrase('German',lang)}</div>
                        </div>
                        <div className={'col-auto item-language'} onClick={() => {
                            this.changeLanguage('cz')
                        }}>
                            <img src={require('../assets/images/czech.png').default}/>
                            <div className={'title'}>{this.getTranslationPhrase('Czech',lang)}</div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={this.state.isAlertModal} onHide={() => {
                this.setState({
                    isAlertModal:false
                })
            }}
            size={'lg'}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <h2>{this.getTranslationPhrase('Alert',lang)}</h2>
                    <p>{this.getTranslationPhrase('When you change product for configuration, you lost configuration of actual product.',lang)} </p>
                    <div className={'form-row'} >
                        <div className={'col-12 text-right row-buttons'}>
                            <button
                                type={'button'}
                                className={'btn btn-primary'}
                                onClick={() => {
                                    this.setState({
                                        type:'products',
                                        isAlertModal:false
                                    })
                                }}

                            >{this.getTranslationPhrase('Continue',lang)} <FontAwesome name={'chevron-right'}/></button>
                            <button
                                type={'button'}

                                onClick={() => {
                                    this.setState({

                                        isAlertModal:false
                                    })
                                }}
                                className={'btn btn-danger'}>{this.getTranslationPhrase('Cancel',lang)} <FontAwesome name={'times'}/></button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal show={this.state.isPDFModal} onHide={() => {
                this.setState({
                    isPDFModal:false
                })
            }}
                   size={'lg'}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <h2>{this.getTranslationPhrase('Download PDF',lang)} </h2>
                    <p><a href={this.state.fileUrl} target={'_blank'}>{this.getTranslationPhrase('Download file',lang)}</a> </p>
                    <div className={'form-row'} >
                        <div className={'col-12 text-right row-buttons'}>

                            <button
                                type={'button'}

                                onClick={() => {
                                    this.setState({

                                        isPDFModal:false
                                    })
                                }}
                                className={'btn btn-danger'}>{this.getTranslationPhrase('Cancel',lang)}  <FontAwesome name={'times'}/></button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>



            <Loader
                type="Bars"
                color="#FFF"
                height={50}
                width={50}
                className={'overlay-loader'}
                visible={this.state.isLoader}
            />
        </React.Fragment>
    }
}
export  default Designer;
